import React from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import Logo from '../logo/Logo';
import solana from '../../images/solana.png';
import './Footer.scss';
import TwitterLink from '../twitter-link/TwitterLink';
import DiscordLink from '../discord-link/DiscordLink';

function Footer(): React.ReactElement {
  return (
    <div className="Footer">
      {isBrowser && (
        <>
          <div className="Footer-Section1">
            <div className="Footer-Content">
              <div className="Footer-Section1-Title">DOUBLE SUM GANG</div>
              <div className="Description">For general enquiries or partner ops please contact.</div>
              <div className="Contact">
                <a href="mailto: contact@doublesumgang.com" style={{ textDecoration: 'none', color: '#9945FF' }}>
                  contact@doublesumgang.com
                </a>
              </div>
            </div>
            <div className="Footer-Content">
              <div className="Footer-Section1-Title">Resources</div>
              <div>Whitepaper (Coming soon)</div>
              <div>
                <a href="https://doublesumgang.com/faq" style={{ textDecoration: 'none', color: 'white' }}>
                  FAQ
                </a>
              </div>
              <div>
                <a href="https://linktr.ee/DoubleSumGang" style={{ textDecoration: 'none', color: 'white' }}>
                  Linktree
                </a>
              </div>
            </div>
            <div className="Footer-Content">
              <div className="Footer-Section1-Title">Resources</div>
              <TwitterLink />
              <DiscordLink />
            </div>
          </div>
          <div className="Footer-Section2">
            <Logo />
            <div className="Footnote">
              Built on the <img src={solana} alt="solona" width="25" height="25" /> Solana blockchain.
            </div>
          </div>
        </>
      )}
      {isMobile && (
        <div className="Mobile-Menu">
          <Logo />
          <TwitterLink />
          <DiscordLink />
        </div>
      )}
    </div>
  );
}

export default Footer;
