import React, { useState } from 'react';
import './Rarity.scss';

const attributes = [
  { id: 1, value: 'CARDS' },
  { id: 2, value: 'HANDS' },
  { id: 3, value: 'WRISTS' },
  { id: 4, value: 'SLEEVES' },
  { id: 5, value: 'TATTOOS' },
  { id: 6, value: 'RINGS' },
  { id: 7, value: 'GLOVES' },
];

const cards = [
  {
    id: 1,
    attributeId: 1,
    textValue: 'Charcoal',
    percentage: 47.07,
    isRare: false,
    imgPath: '/assets/attr/cards/coming_soon.png',
  },
  {
    id: 2,
    attributeId: 1,
    textValue: 'Bronze',
    percentage: 24.33,
    isRare: false,
    imgPath: '/assets/attr/cards/coming_soon.png',
  },
  {
    id: 3,
    attributeId: 1,
    textValue: 'Silver',
    percentage: 18.87,
    isRare: false,
    imgPath: '/assets/attr/cards/coming_soon.png',
  },
  {
    id: 4,
    attributeId: 1,
    textValue: 'Gold',
    percentage: 7.77,
    isRare: true,
    imgPath: '/assets/attr/cards/coming_soon.png',
  },
  {
    id: 5,
    attributeId: 1,
    textValue: 'Diamond',
    percentage: 1.97,
    isRare: true,
    imgPath: '/assets/attr/cards/coming_soon.png',
  },
  {
    id: 7,
    attributeId: 2,
    textValue: 'BROWN',
    percentage: 20.26,
    isRare: false,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 8,
    attributeId: 2,
    textValue: 'TAN',
    percentage: 19.41,
    isRare: false,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 9,
    attributeId: 2,
    textValue: 'WHITE',
    percentage: 19.34,
    isRare: false,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 10,
    attributeId: 2,
    textValue: 'MECHA',
    percentage: 15.35,
    isRare: false,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 11,
    attributeId: 2,
    textValue: 'APE',
    percentage: 15.15,
    isRare: false,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 12,
    attributeId: 2,
    textValue: 'ZOMBIE',
    percentage: 8.37,
    isRare: true,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 13,
    attributeId: 2,
    textValue: 'ALIEN',
    percentage: 2.11,
    isRare: true,
    imgPath: '/assets/attr/hands/coming_soon.png',
  },
  {
    id: 14,
    attributeId: 3,
    textValue: 'None',
    percentage: 33.02,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 15,
    attributeId: 3,
    textValue: 'BRACELET JADE',
    percentage: 6.22,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 16,
    attributeId: 3,
    textValue: 'BRACELET RUBY',
    percentage: 5.86,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 17,
    attributeId: 3,
    textValue: 'DINNER WATCH',
    percentage: 5.64,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 19,
    attributeId: 3,
    textValue: 'SPORTS SILVERFACE',
    percentage: 5.29,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 20,
    attributeId: 3,
    textValue: 'SPORTS GOLDFACE',
    percentage: 4.82,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 21,
    attributeId: 3,
    textValue: 'BRACELET JADE/GOLD',
    percentage: 4.57,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 22,
    attributeId: 3,
    textValue: 'CRYPTOLEX SILVER GOLDFACE',
    percentage: 4.31,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 23,
    attributeId: 3,
    textValue: 'LONGFACE',
    percentage: 4.28,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 24,
    attributeId: 3,
    textValue: 'BRACELET GOLD',
    percentage: 2.86,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 25,
    attributeId: 3,
    textValue: 'CRYPTOLEX GOLD',
    percentage: 2.8,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 26,
    attributeId: 3,
    textValue: 'HERMANDO',
    percentage: 2.64,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 27,
    attributeId: 3,
    textValue: 'CRYPTOLEX REDBLUE',
    percentage: 2.64,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 28,
    attributeId: 3,
    textValue: 'CROSS BRACELET GOLD',
    percentage: 2.48,
    isRare: false,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 29,
    attributeId: 3,
    textValue: 'CRYPTOLEX ICED GOLDFACE',
    percentage: 1.87,
    isRare: true,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 30,
    attributeId: 3,
    textValue: 'CRYPTOLEX ICED RUBYFACE',
    percentage: 1.85,
    isRare: true,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 31,
    attributeId: 3,
    textValue: 'BRACELET DIAMOND GOLD',
    percentage: 1.67,
    isRare: true,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 32,
    attributeId: 3,
    textValue: 'CRYPTOLEX ICED SAPPHIRE',
    percentage: 1.63,
    isRare: true,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 33,
    attributeId: 3,
    textValue: 'CRYPTOLEX DOUBLE ICED',
    percentage: 0.58,
    isRare: true,
    imgPath: '/assets/attr/wrists/coming_soon.png',
  },
  {
    id: 34,
    attributeId: 4,
    textValue: 'NONE',
    percentage: 23.23,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 35,
    attributeId: 4,
    textValue: 'LONG SLEEVE OLIVE',
    percentage: 9.95,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 36,
    attributeId: 4,
    textValue: 'LONG SLEEVE BLUE',
    percentage: 9.66,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 37,
    attributeId: 4,
    textValue: 'LONG SLEEVE PINK',
    percentage: 9.44,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 38,
    attributeId: 4,
    textValue: 'LONG SLEEVE GREEN',
    percentage: 9.11,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 39,
    attributeId: 4,
    textValue: 'LONG SLEEVE ORANGE',
    percentage: 9.08,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 40,
    attributeId: 4,
    textValue: 'LONG SLEEVE PURPLE',
    percentage: 8.78,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 41,
    attributeId: 4,
    textValue: 'BANDANNA RED',
    percentage: 2.02,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 42,
    attributeId: 4,
    textValue: 'SUIT BLUE',
    percentage: 2.01,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 43,
    attributeId: 4,
    textValue: 'BANDANNA GREY',
    percentage: 1.99,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 44,
    attributeId: 4,
    textValue: 'BANDANNA PURPLE',
    percentage: 1.91,
    isRare: false,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 45,
    attributeId: 4,
    textValue: 'SUIT GREY',
    percentage: 1.85,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 46,
    attributeId: 4,
    textValue: 'BANDANNA BLUE',
    percentage: 1.83,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 47,
    attributeId: 4,
    textValue: 'TUXEDO',
    percentage: 1.41,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 48,
    attributeId: 4,
    textValue: 'DENIM RIPPED',
    percentage: 1.4,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 49,
    attributeId: 4,
    textValue: 'SLAV BLACK',
    percentage: 1.04,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 50,
    attributeId: 4,
    textValue: 'SLAV WHITE',
    percentage: 1.04,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 51,
    attributeId: 4,
    textValue: 'SLAV RED',
    percentage: 0.89,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 52,
    attributeId: 4,
    textValue: 'LEATHER WHITE',
    percentage: 0.55,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 53,
    attributeId: 4,
    textValue: 'LEATHER BROWN',
    percentage: 0.53,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 54,
    attributeId: 4,
    textValue: 'GUTTI',
    percentage: 0.51,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 55,
    attributeId: 4,
    textValue: 'RENDI',
    percentage: 0.5,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 56,
    attributeId: 4,
    textValue: 'SLAV BLUE',
    percentage: 0.44,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 57,
    attributeId: 4,
    textValue: 'BANDANNA CAMO',
    percentage: 0.44,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },
  {
    id: 58,
    attributeId: 4,
    textValue: 'VERSARI',
    percentage: 0.38,
    isRare: true,
    imgPath: '/assets/attr/sleeves/coming_soon.png',
  },

  {
    id: 59,
    attributeId: 5,
    textValue: 'NONE',
    percentage: 49.92,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 60,
    attributeId: 5,
    textValue: 'SOLANA',
    percentage: 2.44,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 61,
    attributeId: 5,
    textValue: 'ETHEREUM',
    percentage: 2.33,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 62,
    attributeId: 5,
    textValue: 'HEART',
    percentage: 2.33,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 63,
    attributeId: 5,
    textValue: 'MONK',
    percentage: 2.28,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 64,
    attributeId: 5,
    textValue: 'SHIBA INU',
    percentage: 2.27,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 65,
    attributeId: 5,
    textValue: 'LOL EMOJI',
    percentage: 2.24,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 66,
    attributeId: 5,
    textValue: 'SKULL',
    percentage: 2.22,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 67,
    attributeId: 5,
    textValue: 'SNAKE',
    percentage: 2.2,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 68,
    attributeId: 5,
    textValue: 'GOOD NIGHT',
    percentage: 2.14,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 69,
    attributeId: 5,
    textValue: 'GOOD MORNING',
    percentage: 2.13,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 70,
    attributeId: 5,
    textValue: 'BITCOIN',
    percentage: 2.13,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 71,
    attributeId: 5,
    textValue: 'NOT FINANCIAL ADVICE',
    percentage: 2.12,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 72,
    attributeId: 5,
    textValue: 'EYE',
    percentage: 2.11,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 73,
    attributeId: 5,
    textValue: 'NFT',
    percentage: 2.1,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 74,
    attributeId: 5,
    textValue: 'STONKS',
    percentage: 2.05,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 75,
    attributeId: 5,
    textValue: 'FLAME',
    percentage: 2.04,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 76,
    attributeId: 5,
    textValue: 'BINANCE',
    percentage: 2.04,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 77,
    attributeId: 5,
    textValue: 'SPIDER',
    percentage: 2.02,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 78,
    attributeId: 5,
    textValue: 'LFG',
    percentage: 2.02,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 79,
    attributeId: 5,
    textValue: 'ONE OF ONE',
    percentage: 2.01,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 80,
    attributeId: 5,
    textValue: 'JPEG',
    percentage: 2.0,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 81,
    attributeId: 5,
    textValue: 'CROSS',
    percentage: 1.96,
    isRare: false,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 82,
    attributeId: 5,
    textValue: 'GONNA MAKE IT',
    percentage: 1.85,
    isRare: true,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 83,
    attributeId: 5,
    textValue: '7523',
    percentage: 1.04,
    isRare: true,
    imgPath: '/assets/attr/tattoos/coming_soon.png',
  },
  {
    id: 84,
    attributeId: 6,
    textValue: 'NONE',
    percentage: 49.13,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 85,
    attributeId: 6,
    textValue: 'GOLD BAND',
    percentage: 10.51,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 86,
    attributeId: 6,
    textValue: 'JADE BAND',
    percentage: 10.46,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 87,
    attributeId: 6,
    textValue: 'RUBY BAND',
    percentage: 10.07,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 88,
    attributeId: 6,
    textValue: 'SILVER BAND',
    percentage: 10.07,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 89,
    attributeId: 6,
    textValue: 'FAT GOLD',
    percentage: 7.96,
    isRare: false,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 90,
    attributeId: 6,
    textValue: 'ICE ROCK',
    percentage: 1.79,
    isRare: true,
    imgPath: '/assets/attr/rings/coming_soon.png',
  },
  {
    id: 91,
    attributeId: 7,
    textValue: 'NONE',
    percentage: 54.17,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 92,
    attributeId: 7,
    textValue: 'FINGERLESS PURPLE',
    percentage: 3.34,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 93,
    attributeId: 7,
    textValue: 'FINGERLESS BLACK',
    percentage: 3.31,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 94,
    attributeId: 7,
    textValue: 'FINGERLESS RED',
    percentage: 3.24,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 95,
    attributeId: 7,
    textValue: 'BIKER PURPLE',
    percentage: 3.22,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 96,
    attributeId: 7,
    textValue: 'BIKER PINK',
    percentage: 3.21,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 97,
    attributeId: 7,
    textValue: 'BIKER BLUE',
    percentage: 3.1,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 98,
    attributeId: 7,
    textValue: 'MOTO',
    percentage: 3.07,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 99,
    attributeId: 7,
    textValue: 'FINGERLESS YELLOW',
    percentage: 3.03,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 100,
    attributeId: 7,
    textValue: 'BIKER RED',
    percentage: 2.99,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 101,
    attributeId: 7,
    textValue: 'BIKER BLACK',
    percentage: 2.84,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 102,
    attributeId: 7,
    textValue: 'CAMO',
    percentage: 2.12,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 103,
    attributeId: 7,
    textValue: 'STANDARD PURPLE',
    percentage: 2.03,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 104,
    attributeId: 7,
    textValue: 'COVID',
    percentage: 1.98,
    isRare: false,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 105,
    attributeId: 7,
    textValue: 'BOXING GLOVES',
    percentage: 1.8,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 106,
    attributeId: 7,
    textValue: 'SKULLY',
    percentage: 1.24,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 107,
    attributeId: 7,
    textValue: 'KNUCKLE DUSTER',
    percentage: 1.16,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 108,
    attributeId: 7,
    textValue: 'RENDI',
    percentage: 1.11,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 109,
    attributeId: 7,
    textValue: 'LEATHER BROWN',
    percentage: 1.04,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 110,
    attributeId: 7,
    textValue: 'UFR',
    percentage: 1.03,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
  {
    id: 111,
    attributeId: 7,
    textValue: 'STANDARD RAINBOW',
    percentage: 0.94,
    isRare: true,
    imgPath: '/assets/attr/gloves/coming_soon.png',
  },
];

function Rarity(): React.ReactElement {
  const [selectedAttribute, setSelectedAttribute] = useState(1);

  const handleClickAttribute = (attribId: number) => {
    setSelectedAttribute(attribId);
  };

  return (
    <div className="Rarity">
      <div className="Attributes">
        {attributes.map((attribute) => (
          <div
            key={attribute.id}
            className={`Attribute ${attribute.id === selectedAttribute ? 'Active' : ''}`}
            onClick={() => handleClickAttribute(attribute.id)}
          >
            {attribute.value}
          </div>
        ))}
      </div>
      <div className="Cards">
        {cards
          .filter((card) => card.attributeId === selectedAttribute)
          .map((card) => (
            <div key={card.id} className="Card">
              {card.isRare ? (
                <div className="Rare">
                  <span className="RareStar">*</span> RARE
                </div>
              ) : (
                <div className="Rare"></div>
              )}
              <img src={card.imgPath} alt="blankImage" />
              {card.textValue} <br />
              {card.percentage + '%'}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Rarity;
