import React from 'react';

import './RoadMap.scss';

// 0 - not completed
// 1 - completed
// 2 - deleted
const roadMapItems = [
  {
    id: 1,
    title: 'Stage 1',
    subTitle: 'LAUNCH DOUBLE SUM GANG',
    desc: `7,776 Unique Double Sum Gang hands to be used in-game. 
2592 Rock Hands, 2592 Scissor Hands, 2592 Paper Hands are created with multiple races (Human, Zombie, Alien, Ape, Mecha). 
Airdrops and prizes for early supporters`,
    status: 1,
  },
  {
    id: 2,
    title: 'Stage 2',
    subTitle: 'PUBLIC MINTING & SECONDARY MARKET SALES',
    desc: `Public minting to launch in late  December. \n 
           Holders will not need all 3 hand types to play game on release \n
           MagicEden (Planned)`,
    status: 0,
  },
  {
    id: 3,
    title: 'Stage 3',
    subTitle: 'FORMATION OF DOUBLE SUM DAO (V1)',
    desc: `The first release of the Double Sum DAO will be used as a voting contract 
           to allow gang members to contribute directly to the direction and success 
           of the project. We are currently considering @squads_dapp`,
    status: 0,
  },
  {
    id: 4,
    title: 'Stage 4',
    subTitle: 'DEVELOPMENT & RELEASE OF DOUBLE SUM GAME',
    desc: `First release of Double Sum Game, an on-chain PVP wagering game where people go head to head in games of rock, paper scissors. 
DSG Holders will be able to use their NFTs as in-game skins`,
    status: 0,
  },
  {
    id: 5,
    title: 'Stage 5',
    subTitle: 'RELEASE OF DOUBLE SUM GANG NFTs (SECOND EDITION)',
    desc: `9,799 Unique, in-game playable characters will be released via public mint
200 original Double Sum Gang holders will have custom designed characters created to their preference`,
    status: 0,
  },
  {
    id: 6,
    title: 'Stage 6',
    subTitle: 'OBTAIN CASINO LICENSE & LAUNCH DOUBLE SUM CITY (METAVERSE CASINO)',
    desc: `Interactive metaverse city with casino where two players wager up anything they want NFT vs. ETH, ETH vs. DOGE, etc in a winner-takes all game of rock-paper-scissors. 
Depending on Double Sum Gang member votes this could also include additional new, provably fair games.`,
    status: 0,
  },
  {
    id: 7,
    title: 'Stage 7',
    subTitle: 'DOUBLE SUM DAO (V2) LIFETIME HOLDER ROYALTIES',
    desc: `Original Double Sum Gang (First edition) holders will recieve lifetime royalties generated from 48% of the casino earnings
Royalties will be paid monthly`,
    status: 0,
  },
  {
    id: 8,
    title: 'Stage 8',
    subTitle: 'CONTINUED EXPANSION & INNOVATION',
    desc: `Using the Double Sum DAO, Double Sum Gang NFT holders will be able \n
           to vote in order to contribute to the direction and future expansion of the project.\n`,
    status: 0,
  },
];

function RoadMap(): React.ReactElement {
  return (
    <div className="RoadMap">
      <div className="Content">
        {roadMapItems.map((item) => (
          <div className="RoadMapItem" key={item.title}>
            <div>
              <div className="Title">{item.title}</div>
              <div className="SubTitle">{item.subTitle}</div>
              <div className="Desc">{item.desc}</div>
            </div>
            <div style={{ cursor: 'pointer' }}>{item.status === 1 ? <div>[X]</div> : <div>[]</div>}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RoadMap;
