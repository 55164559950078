import React, { useState } from 'react';
import { WalletMultiButton, WalletDisconnectButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { isBrowser, isMobile } from 'react-device-detect';

import Logo from '../logo/Logo';
import MenuItem from '../menu-item/MenuItem';
import MenuButton from '../mobile-menu/MenuButton';
import MobileMenu from '../mobile-menu/MobileMenu';
import './AppBar.scss';

type Props = {
  startDate: Date;
};

function AppBar({ startDate }: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const { publicKey } = useWallet();

  const menuItems = () => {
    const pathData = [
      { path: '/rarity', pathName: 'RARITY' },
      { path: '/about', pathName: 'ABOUT' },
      { path: '/road-map', pathName: 'ROADMAP' },
      { path: '/faq', pathName: 'FAQ' },
      { path: '/white-paper', pathName: 'WHITEPAPER' },
    ];

    return pathData.map((e, index) => (
      <MenuItem key={index} path={e.path} pathName={e.pathName} setIsOpen={setIsOpen} />
    ));
  };

  return (
    <div className="App-Bar">
      <Logo />
      {isBrowser && (
        <>
          <div className="Menu">{menuItems()}</div>

          <div className="Connect">
            {publicKey ? (
              <WalletDisconnectButton className="Connect-Btn" />
            ) : (
              <WalletMultiButton className="Connect-Btn" disabled={startDate?.getTime() > new Date().getTime()} />
            )}
          </div>
        </>
      )}
      {isMobile && (
        <>
          <MenuButton isOpen={isOpen} setIsOpen={setIsOpen} />
          <MobileMenu isOpen={isOpen}>{menuItems()}</MobileMenu>
        </>
      )}
    </div>
  );
}

export default AppBar;
