import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isBrowser, isMobile } from 'react-device-detect';
import './Logo.scss';

function Logo(): React.ReactElement {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="Logo" onClick={navigateHome}>
      <div className="Logo-Img">
        <img src="/assets/dsg.png" alt="Dog" height="40" width="40" />
      </div>
      {isBrowser && 'DOUBLE SUM GANG'}
      {isMobile && 'DSG'}
    </div>
  );
}

export default Logo;
