import React from 'react';
import twitter from '../../images/twitter.png';

export default function TwitterLink(): React.ReactElement {
  return (
    <>
      <a
        href="https://twitter.com/doublesumgang"
        style={{ textDecoration: 'none', color: '#9945FF' }}
        target="_blank"
        rel="noreferrer"
      >
        <img src={twitter} alt="twitter logo" width="50" height="50" />
      </a>
    </>
  );
}
