import React, { useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import * as anchor from '@project-serum/anchor';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from '@solana/wallet-adapter-wallets';

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';

import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

import AppBar from './components/app-bar/AppBar';
import FAQ from './pages/faq/FAQ';
import Landing from './pages/landing/Landing';
import Rarity from './pages/rarity/Rarity';
import About from './pages/about/About';
import RoadMap from './pages/road-map/RoadMap';
import Whitepaper from './pages/whitepaper/Whitepaper';

import './App.css';

require('@solana/wallet-adapter-react-ui/styles.css');

const treasury = new anchor.web3.PublicKey(process.env.REACT_APP_TREASURY_ADDRESS!);

const config = new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_CONFIG!);

const candyMachineId = new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = new Date(parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10) * 1000);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

function App(): React.ReactElement {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    [],
  );

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <Router>
            <div className="App">
              <AppBar startDate={startDateSeed} />
              <Routes>
                <Route path="/faq" element={<FAQ />} />
                <Route path="/rarity" element={<Rarity />} />
                <Route path="/about" element={<About />} />
                <Route path="/road-map" element={<RoadMap />} />
                <Route path="/white-paper" element={<Whitepaper />} />
                <Route
                  path="/"
                  element={
                    <Landing
                      candyMachineId={candyMachineId}
                      config={config}
                      connection={connection}
                      startDate={startDateSeed}
                      treasury={treasury}
                      txTimeout={txTimeout}
                    />
                  }
                />
              </Routes>
            </div>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;
