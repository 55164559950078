import React from 'react';
import discord from '../../images/discord.png';

export default function DiscordLink(): React.ReactElement {
  return (
    <>
      <a
        href="https://discord.com"
        style={{ textDecoration: 'none', color: '#9945FF' }}
        target="_blank"
        rel="noreferrer"
      >
        <img src={discord} alt="logo2" width="50" height="50" />
      </a>
    </>
  );
}
