import React from 'react';
import Collapse from '../../components/collapse/Collapse';
import Footer from '../../components/footer/Footer';
import './FAQ.scss';

const data = [
  {
    q: 'WHAT IS THE TOTAL DOUBLE SUM GANG SUPPLY?',
    a: 'The total supply of the Double Sum Gang collection is 7,776',
  },
  {
    q: 'WHEN MINT?',
    a: 'Double Sum Gang minting will begin in late December 2021. Stay tuned to Twitter for further updates @doublesumgang.',
  },
  {
    q: 'WHAT WILL BE THE MINTING COST?',
    a: 'Each Double Sum Gang NFT will cost 1 SOL to mint (each).',
  },
  {
    q: 'WHAT WALLETS WILL BE SUPPORTED?',
    a: 'You will be able to use the following wallets: Phantom, Sollet, Slope and Solflare',
  },
  {
    q: 'WILL THERE BE A PURCHASE LIMIT PER WALLET?',
    a: 'No, there will be no limit per-wallet.',
  },
];

function FAQ(): React.ReactElement {
  return (
    <div className="FAQ">
      <div className="Main">
        <div className="Title">FAQ</div>
        <div className="Questions">
          {data.map((item) => (
            <Collapse key={item.q} collapseTitle={item.q} collapseContent={item.a} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
