import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';

import * as anchor from '@project-serum/anchor';

import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from './candy-machine';

import twitter from '../../images/twitter.png';
import discord from '../../images/discord.png';
import logo3 from '../../images/logo3.png';
import correct from '../../images/correct.png';
import blankImage from '../../images/doublesumgang.gif';

import './Landing.scss';

type Props = {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: Date;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
};

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  const allHours = hours + (days || 0) * 24;
  return (
    <div>
      {allHours < 10 ? '0' + allHours : allHours}:{minutes < 10 ? '0' + minutes : minutes}:
      {seconds < 10 ? '0' + seconds : seconds}
    </div>
  );
};

function Landing({ startDate, candyMachineId, connection, config, treasury, txTimeout }: Props): React.ReactElement {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [mintStartDate, setMintStartDate] = useState(startDate);

  const wallet = useAnchorWallet();

  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const { candyMachine, goLiveDate, itemsAvailable, itemsRemaining, itemsRedeemed } = await getCandyMachineState(
        wallet as anchor.Wallet,
        candyMachineId,
        connection,
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setMintStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, connection]);

  useEffect(refreshCandyMachineState, [wallet, candyMachineId, connection]);

  const onMint = async () => {
    try {
      if (isSoldOut || isMinting || !isActive) return;

      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(candyMachine, config, wallet.publicKey, treasury);

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          txTimeout,
          connection,
          'singleGossip',
          false,
        );

        if (!status?.err) {
          alert('Congratulations! Mint succeeded!');
        } else {
          alert('Mint failed! Please try again!');
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || 'Minting failed! Please try again!';
      if (!error.msg) {
        if (error.message.indexOf('0x138')) {
        } else if (error.message.indexOf('0x137')) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf('0x135')) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      alert(message);
    } finally {
      if (wallet) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  //  const renderLandingButton = () => {
  //   if (mintStartDate.getTime() > new Date().getTime()) {
  //     return (
  //       <div className="Landing-Btn" style={{ color: '#F803FE', fontSize: 24 }}>
  //         <Countdown
  //           date={mintStartDate}
  //          onMount={({ completed }) => completed && setIsActive(true)}
  //           onComplete={() => setIsActive(true)}
  //          renderer={renderCounter}
  //         />
  //       </div>
  //     );
  // }
  // if (wallet) {
  //   return (
  //     <>
  //       <div style={{ color: '#84FFFF', fontSize: 32 }}>
  //         {itemsRedeemed}/{itemsAvailable}
  //         <br />
  //         MINTED
  //       </div>
  //       <div style={{ fontSize: 22 }}>Price: 0.01 SOL</div>
  //       <div
  //         // disabled={isSoldOut || isMinting || !isActive}
  //         onClick={onMint}
  //         className="Landing-Btn"
  //         style={{ color: '#F803FE', fontSize: 24 }}
  //       >
  //         {isSoldOut ? (
  //           'SOLD OUT'
  //         ) : isActive ? (
  //           isMinting ? (
  //             'MINTING...'
  //           ) : (
  //             'MINT NOW'
  //           )
  //         ) : (
  //           <Countdown
  //             date={mintStartDate}
  //             onMount={({ completed }) => completed && setIsActive(true)}
  //             onComplete={() => setIsActive(true)}
  //             renderer={renderCounter}
  //           />
  //         )}
  //       </div>
  //     </>
  //   );
  // } else {
  //   return <WalletMultiButton className="Landing-Btn" />;
  // }
  // };

  return (
    <div className="Landing">
      <div className="Section1">
        <div className="Title">
          Double Sum Gang is an <br /> exclusive collection <br /> of {''}
          <span className="Count">7,776</span> utility-driven <br /> NFT hands flexing on <br /> the Solana blockchain.
        </div>
        <div className="Info">
          <a
            href="https://twitter.com/doublesumgang"
            style={{ textDecoration: 'none', color: '#9945FF' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter logo" width="65" height="65" style={{ marginRight: '2%' }} />
          </a>
          <a
            href="https://discord.com"
            style={{ textDecoration: 'none', color: '#9945FF' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={discord} alt="discord" width="65" height="65" style={{ marginRight: '2%' }} />
          </a>
          <div className="Feature">
            <img src={correct} alt="correct" width="45" height="45" />
            <p className="Feature-Value">UTILITY DRIVEN NFTs (PVP/ON-CHAIN) GAMING</p>
          </div>
          <div className="Feature">
            <img src={correct} alt="correct" width="45" height="45" />
            <p className="Feature-Value">DAO WILL ENSURE LIFETIME REVENUE FOR HOLDERS</p>
          </div>
          <div className="Feature">
            <img src={correct} alt="correct" width="45" height="45" />
            <p className="Feature-Value">
              LONG-TERM VISION INCLUDING <br />
              DOUBLE SUM GANG METAVERSE-CASINO
            </p>
          </div>
        </div>
      </div>
      <div className="Section2">
        <div className="Card">
          {wallet && <div className="WalletID">Wallet {shortenAddress(wallet.publicKey.toBase58() || '')}</div>}
          <div className="Images">
            <img className="BlankImage" src={blankImage} alt="blankImage" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
